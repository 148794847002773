import { Lazy, Mousewheel, Navigation, Pagination, Swiper } from 'swiper/dist/js/swiper.esm.js';

Swiper.use([Lazy, Mousewheel, Navigation, Pagination]);

const DEFAULT_SWIPER_OPTIONS = {
	centeredSlides: true,
	grabCursor: true,
	mousewheel: true,
	slidesPerView: 1,
	spaceBetween: 5,
	freeMode: true,
	lazy: {
		loadPrevNext: true
	},
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
	},
	preloadImages: false,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
};

class BasicGallery {
	private readonly selector: string;
	private readonly options: Object;
	private readonly gallery: Swiper;
	public slideTo;

	constructor(selector: string, options: Object = {}) {
		this.selector = selector;
		this.options = options;
		this.gallery = this.createGallery();
		this.slideTo = this.gallery.slideTo.bind(this.gallery);
	}

	createGallery() {
		return new Swiper(
			this.selector,
			{
				...DEFAULT_SWIPER_OPTIONS,
				...this.options
			}
		);
	}
}

export default BasicGallery;