import $ from '../common/nano';

class TherapiesList {
	constructor() {
		this.initEvents();
	}

	initEvents() {
		$('.TherapiesMainListView').on('click', (ev) => {
			const clicked = $(ev.target);

			if (clicked.hasClass('show-therapies-sub-list') &&
				!clicked.hasClass('is-selected')
			) {
				this.hideActiveTherapiesSubList();
				this.showTherapiesSubList(clicked);

				$('.show-therapies-sub-list.is-selected').removeClass('is-selected');
				clicked.addClass('is-selected');
			}
		});
	}

	hideActiveTherapiesSubList() {
		const active = $('.TherapiesSubListView-list.is-active');

		active
			.addClass('hidden-element')
			.removeClass('is-active u-fadeIn');
	}

	showTherapiesSubList(source) {
		const target = $(
			source.get().getAttribute('data-target')
		);

		target
			.addClass('is-active u-fadeIn')
			.removeClass('hidden-element')
	}
}

export default TherapiesList;